import { Button } from "@progress/kendo-react-buttons";
import { Icon } from "@progress/kendo-react-common";
import type { SelectionRange } from "@progress/kendo-react-dateinputs";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import _ from "lodash";
import {
	type ComponentProps,
	Fragment,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useLocation, useTitle } from "react-use";
import { JobForm } from "./JobForm";
import { JobsGrid } from "./JobsGrid";
import { LocationFormWithDTO } from "./LocationFormWithDTO";
import { TypedDropDownButton } from "./TypedDropDownButton";
import type { JobActions } from "./api/JobApi";
import {
	DocumentUsage,
	NewJobStatus,
	TableNameType,
	type TypedGridColumnProps,
	jobApi,
	jobStatusNamesAndColors,
	toCurrency,
	toDatetimeString,
	toasted,
	uploadDocuments,
	useLookupConstraints,
	useLookupCustomers,
	useLookupJobTypes,
	useLookupLocations,
	useLookupUsers,
} from "./helpers";
import {
	useAuditForm,
	useDialog,
	useGenericDateRangePicker,
	useGenericJobStatusFilter,
} from "./helpersReact";

type JobsGridProps = ComponentProps<typeof JobsGrid>;
type Job = JobsGridProps["data"][number];
type JobFormProps = ComponentProps<typeof JobForm>;
type JobFormObject = Parameters<JobFormProps["onSubmit"]>[0];

type ActionItem = {
	text?: string;
	action: keyof JobActions;
	data: Job;
};
type StatusItem = ActionItem & {
	status?: NewJobStatus;
};

const Status = ({ statusString }: { statusString: string }) => (
	<span>
		<Icon name="circle" />
		{statusString}
	</span>
);

const StatusItemRender = ({ item }: { item: StatusItem }) => (
	<Status statusString={item.text ?? ""} />
);

const useHandleAction = (
	retry: () => void,
	showEditJob: (jobId: number, duplicate: boolean) => void,
	showAuditFor: (id: string | number) => void,
) => {
	const navigate = useNavigate();
	const location = useLocation();
	return useCallback(
		(item: ActionItem): undefined => {
			switch (item.action) {
				case "allowViewDetails":
					navigate(`/jobs/${item.data.id}`, {
						state: { from: location.pathname },
					});
					break;
				case "allowEdit":
					showEditJob(item.data.id, false);
					break;
				case "allowDuplicate":
					showEditJob(item.data.id, true);
					break;
				case "allowPause":
					toasted(
						jobApi.job.jobChangeStatusPauseCreate(item.data.id),
						"Pausing Job",
					).then(retry);
					break;
				case "allowSendToInvoice":
					toasted(
						jobApi.job.jobChangeStatusCreate(
							item.data.id,
							NewJobStatus.READY_FOR_INVOICE,
						),
						"Sending to Invoice",
					).then(retry);
					break;
				case "allowCancel":
					toasted(
						jobApi.job.jobChangeStatusCancelCreate(item.data.id),
						"Cancelling Job",
					).then(retry);
					break;
				case "allowGenerateFileFront":
					toasted(
						jobApi.job
							.jobReportFileFrontDetail(item.data.id, { format: "blob" })
							.then((x) => window.open(URL.createObjectURL(x.data), "_blank")),
						"Generating File Front",
					).then(retry);
					break;
				case "allowForceComplete":
					toasted(
						jobApi.job.jobForceCompleteCreate(item.data.id),
						"Forcing Completion",
					).then(retry);
					break;
				case "isInvoiced":
				case "allowBulkLegsStatusChange":
				case "allowAudit": {
					showAuditFor(item.data.id);
					if (item.data.isNew) {
						toasted(
							jobApi.job.jobIsNewUpdate({
								id: item.data.id,
								isNew: false,
							}),
							"Marking as Read",
						).then(retry);
					}
					break;
				}
				case "allowResume": {
					toasted(
						jobApi.job.jobChangeStatusResumeCreate(item.data.id),
						"Resuming Job",
					).then(retry);
					break;
				}
				case "allowMarkAsSent":
					break;
				case "allowPreviewInvoice":
					break;
				case "allowReInvoice":
					break;
				case "allowSendToInvoicingCheck":
					break;
				case "allowSendToOperations":
					break;
				default:
					return item.action;
			}
		},
		[location.pathname, navigate, showEditJob, showAuditFor, retry],
	);
};

const useExtraColumns = (handleAction: (item: ActionItem) => undefined) => {
	return useMemo(() => {
		const _columns: TypedGridColumnProps<Job>[] = [
			{
				title: "Actions",
				cell: ({ dataItem }) => {
					const items: StatusItem[] = [];
					if (dataItem.actions.allowEdit) {
						items.push({
							text: "Edit",
							action: "allowEdit",
							data: dataItem,
						});
					}
					if (dataItem.actions.allowDuplicate) {
						items.push({
							text: "Duplicate",
							action: "allowDuplicate",
							data: dataItem,
						});
					}
					if (dataItem.actions.allowPause) {
						items.push({
							text: "Pause",
							action: "allowPause",
							data: dataItem,
						});
					}
					if (dataItem.actions.allowSendToInvoice) {
						items.push({
							text: "Send to Invoice",
							action: "allowSendToInvoice",
							data: dataItem,
						});
					}
					if (dataItem.actions.allowCancel) {
						items.push({
							text: "Cancel",
							action: "allowCancel",
							data: dataItem,
						});
					}
					if (dataItem.actions.allowResume) {
						items.push({
							text: "Resume",
							action: "allowResume",
							data: dataItem,
						});
					}
					if (dataItem.actions.allowForceComplete) {
						items.push({
							text: "Force Complete",
							action: "allowForceComplete",
							data: dataItem,
						});
					}
					if (dataItem.actions.allowGenerateFileFront) {
						items.push({
							text: "Generate File Front",
							action: "allowGenerateFileFront",
							data: dataItem,
						});
					}
					if (dataItem.actions.allowAudit) {
						items.push({
							text: `Audit${dataItem.isNew ? " (Mark as read)" : ""}`,
							action: "allowAudit",
							data: dataItem,
						});
					}
					return (
						<td>
							<Button
								size="small"
								icon="eye"
								title="View Details"
								onClick={() =>
									handleAction({ action: "allowViewDetails", data: dataItem })
								}
							/>
							<TypedDropDownButton
								size="small"
								icon="more-vertical"
								title="More Actions"
								items={items}
								itemRender={StatusItemRender}
								onItemClick={(x) => handleAction(x.item)}
								popupSettings={{ animate: false }}
							/>
						</td>
					);
				},
				field: "actions",
				width: "70px",
			},
		];
		return _columns;
	}, [handleAction]);
};

const useFetchData = (rangeValues: SelectionRange, statusValues: number[]) => {
	const _jobs = useQuery({
		queryKey: [
			"jobApi.job.jobV2List",
			rangeValues.start,
			rangeValues.end,
			statusValues,
		],
		queryFn: () =>
			jobApi.job
				.jobV2List({
					DateFrom: rangeValues.start?.toISOString(),
					DateTo: rangeValues.end?.toISOString(),
					StatusIds: statusValues,
				})
				.then((x) => x.data),
		initialData: [],
		refetchInterval: 1000 * 30, // 30 seconds
	});
	const _currencies = useQuery({
		queryKey: ["jobApi.currency.currencyList"],
		queryFn: () => jobApi.currency.currencyLookupList().then((x) => x.data),
		initialData: [],
	});
	const jobs = useMemo(
		() =>
			_jobs.data.map((item) => {
				const startDate = new Date(item.startDate ?? "");
				const endDate = new Date(item.endDate ?? "");
				const job: Job = {
					actions: item.actions,
					assignedToName: item.assignedToName ?? "",
					customerName: item.customerName ?? "",
					endDate,
					endDateString: toDatetimeString(endDate),
					endLocationName: item.endLocationName ?? "",
					id: item.id ?? 0,
					poNumber: item.purchaseOrderNumber ?? "",
					price: item.price ?? undefined,
					cost: item.cost ?? undefined,
					currencyCode: _currencies.data.find((x) => x.id === item.currencyId)
						?.code,
					startDate,
					startDateString: toDatetimeString(startDate),
					startLocationName: item.startLocationName ?? "",
					status: item.status ?? 0,
					statusName: jobStatusNamesAndColors[item.status ?? 0].name ?? "",
					type: item.jobTypeId ?? 0,
					typeName: item.jobTypeName ?? "",
					uniqueId: item.uniqueId ?? "",
					driverName: item.driverName ?? "",
					subcontractorName: item.subcontractorName ?? "",
					isNew: item.isNew,
				};
				return job;
			}),
		[_jobs.data, _currencies.data],
	);
	return { jobs, retry: _jobs.refetch, loading: _jobs.isFetching };
};

const JobFormWithDTO = ({
	editId,
	isDuplicate,
	showLocationForm,
}: {
	editId?: number;
	isDuplicate?: boolean;
	showLocationForm: (callback: (value: number) => void) => void;
}) => {
	const _job = useQuery({
		queryKey: ["jobApi.job.getJob2", editId],
		queryFn: () => {
			if (!editId) return Promise.resolve(null);
			return jobApi.job.getJob2(editId).then((x) => x.data);
		},
		refetchOnWindowFocus: false,
	});
	const navigate = useNavigate();
	const location = useLocation();
	const lookupCustomers = useLookupCustomers();
	const lookupUsers = useLookupUsers();
	const [locationCreated, setLocationCreated] = useState(0);
	const lookupLocations = useLookupLocations([locationCreated]);
	const lookupConstraints = useLookupConstraints();
	const lookupJobTypes = useLookupJobTypes();

	const handleJobFormSubmit = useCallback(
		async (data: JobFormObject) => {
			const saveData = async (data: JobFormObject) => {
				const endDate = data.endDate
					? dayjs(data.endDate).format("YYYY-MM-DDTHH:mm:ss")
					: undefined;
				const startDate = data.startDate
					? dayjs(data.startDate).format("YYYY-MM-DDTHH:mm:ss")
					: undefined;

				if (!endDate || !startDate) throw new Error("Invalid dates");

				if (editId && !isDuplicate) {
					await jobApi.job.putJob({
						...data,
						id: editId,
						endDate,
						startDate,
					});
					await uploadDocuments(
						data.documents ?? [],
						editId,
						DocumentUsage.Job,
						false,
					);
					navigate(`/jobs/${editId}`, { state: { from: location.pathname } });
					return;
				}

				const response = await jobApi.job.postJob({
					...data,
					endDate,
					startDate,
				});

				const jobId = response.data.id;

				await uploadDocuments(
					data.documents ?? [],
					jobId,
					DocumentUsage.Job,
					false,
				);
				navigate(`/jobs/${jobId}`, { state: { from: location.pathname } });
			};
			await toasted(saveData(data), data.id ? "Updating Job" : "Creating Job");
		},
		[navigate, location, editId, isDuplicate],
	);

	const defaultValues: Partial<JobFormObject> = useMemo(() => {
		if (!_job.data) {
			return {
				startDate: dayjs().startOf("d").add(8, "h").toDate(),
				endDate: dayjs().startOf("d").add(16, "h").toDate(),
				purchaseOrderNumber: "TBC",
				assignedTo: JSON.parse(
					JSON.parse(localStorage["persist:authentication"] ?? "{}").userId ??
						'""',
				),
				goodsInfo: {
					name: "TBC",
					quantity: 1,
					weight: 1,
				},
				useVat: true,
			};
		}
		return {
			startDate: new Date(_job.data.startDate),
			endDate: new Date(_job.data.endDate),
			purchaseOrderNumber: _job.data.purchaseOrderNumber ?? undefined,
			assignedTo: _job.data.assignedTo ?? undefined,
			containerNumber: _job.data.containerNumber ?? undefined,
			containerPin: _job.data.containerPin ?? undefined,
			containerSealNumber: _job.data.containerSealNumber ?? undefined,
			customerId: _job.data.customer?.id ?? undefined,
			endLocationId: _job.data.endLocation?.id ?? undefined,
			startLocationId: _job.data.startLocation?.id ?? undefined,
			id: isDuplicate ? undefined : _job.data.id,
			jobTypeId: _job.data.jobType?.id ?? undefined,
			notes: _job.data.notes ?? undefined,
			price: _job.data.price ?? undefined,
			useVat: _job.data.useVat ?? undefined,
			goodsInfo: {
				name: "TBC",
				quantity: 1,
				weight: 1,
			},
		};
	}, [_job.data, isDuplicate]);

	if (_job.isFetching) return <div>Loading...</div>;

	return (
		<JobForm
			lUsers={lookupUsers}
			lConstraints={lookupConstraints}
			lCustomers={lookupCustomers}
			lLocations={lookupLocations}
			lJobTypes={lookupJobTypes}
			onSubmit={handleJobFormSubmit}
			defaultValues={defaultValues}
			onAddLocation={(x) => {
				showLocationForm((id) => {
					x(id);
					setLocationCreated((x) => x + 1);
				});
			}}
		/>
	);
};

const useExtraFilters = () => {
	const [dateRangeEl, rangeValues] = useGenericDateRangePicker("Jobs");
	const [filterStatusEl, statusValues] = useGenericJobStatusFilter("Jobs");
	return [
		<Fragment key="extraFilters">
			{filterStatusEl}
			{dateRangeEl}
		</Fragment>,
		rangeValues,
		statusValues,
	] as const;
};

const useJobForm = (
	showLocationForm: (callback: (value: number) => void) => void,
) => {
	const [editJobId, setEditJobId] = useState<number>();
	const [isDuplicate, setIsDuplicate] = useState<boolean>();
	const [toggleJobFormDialog, jobFormDialog] = useDialog(
		<JobFormWithDTO
			editId={editJobId}
			isDuplicate={isDuplicate}
			showLocationForm={showLocationForm}
		/>,
		editJobId && !isDuplicate ? "Edit Job" : "Create Job",
	);
	const showJobForm = useCallback(
		(jobId?: number, duplicate?: boolean) => {
			setEditJobId(jobId);
			setIsDuplicate(duplicate);
			toggleJobFormDialog();
		},
		[toggleJobFormDialog],
	);
	return { showJobForm, jobFormDialog };
};

const useLocationForm = () => {
	const [callback, setCallback] = useState<(value: number) => void>();
	const [toggleLocationFormDialog, locationFormDialog] = useDialog(
		<LocationFormWithDTO
			onSubmit={(x) => {
				x.id && callback?.(x.id);
				toggleLocationFormDialog(false);
			}}
		/>,
		"Create Location",
	);
	const showLocationForm = useCallback(
		(cb: (value: number) => void) => {
			setCallback(() => cb);
			toggleLocationFormDialog();
		},
		[toggleLocationFormDialog],
	);
	return { showLocationForm, locationFormDialog };
};

export const JobsPage2 = () => {
	useTitle("Jobs");
	const { showLocationForm, locationFormDialog } = useLocationForm();
	const { showAuditFor, auditDialog } = useAuditForm(TableNameType.Job);
	const { showJobForm, jobFormDialog } = useJobForm(showLocationForm);
	const [extraFilters, rangeValues, statusValues] = useExtraFilters();
	const { jobs, retry, loading } = useFetchData(rangeValues, statusValues);
	const handleAction = useHandleAction(retry, showJobForm, showAuditFor);
	const extraColumns = useExtraColumns(handleAction);
	return (
		<>
			{auditDialog}
			{jobFormDialog}
			{locationFormDialog}
			<JobsGrid
				data={jobs}
				extraColumns={extraColumns}
				refresh={retry}
				extraFilters={extraFilters}
				extraButtons={
					<Button
						icon="plus"
						themeColor="primary"
						onClick={() => showJobForm()}
					>
						Add Job
					</Button>
				}
				loading={loading}
				footer={{
					price: (data) => (
						<td>
							{toCurrency(
								_.sumBy(
									// TODO https://celerum.atlassian.net/browse/TLS4-808 NEEDS TO BE REMOVED ONCE THE BACKEND IS FIXED
									data.filter((item) => item.status !== NewJobStatus.CANCELLED),
									"price",
								) || 0,
							)}
						</td>
					),
					cost: (data) => (
						<td>
							{toCurrency(
								_.sumBy(
									data.filter((item) => item.status !== NewJobStatus.CANCELLED),
									"cost",
								) || 0,
							)}
						</td>
					),
				}}
			/>
		</>
	);
};
